import { sxAjax } from '@/commons/ajax';
import { stringify } from 'qs';
declare var abp: any;
export default {
    namespace: 'dashReport',
    initialState: {
        appId: '',
        appName: '',
        entryId: '',
        name: '',
        components: [],
        properties: {
            isPublic: false,
            notify: { enabled: false }
        },
        reLoading: false,//重新加载组件,
        isDraggable: false,
        isResizable: false,
        preview: false,//预览
        sourceForms: [],//数据源表单,
        editComponent: null,//正在编辑的子组件
        editRemarkComponent: null,//正在编辑的文字组件
        memberList: [],//表单发布成员列表,
        permissionType: {
            publish: 1,///表单发起的权限类型
            view: 2,///浏览流程的权限类型
            manager: 3///管理流程的类型
        },
        reload: false,
        Opermissions: [],//权限
    },
    setUnmount: () => {
        return {
            appId: '',
            appName: '',
            entryId: '',
            name: '',
            components: [],
            properties: {
                isPublic: false,
                notify: { enabled: false }
            },
            isDraggable: false,
            isResizable: false,
            preview: false,//预览
            sourceForms: [],//数据源表单,
            editComponent: null,//正在编辑的子组件
            editRemarkComponent: null,//正在编辑的文字组件
            memberList: [],//表单发布成员列表,
            permissionType: {
                publish: 1,///表单发起的权限类型
                view: 2,///浏览流程的权限类型
                manager: 3///管理流程的类型
            },
            reload: false,
            Opermissions: [],//权限
        }
    },
    setName: (name, _state) => {
        return { name };
    },
    setComponents: (components, _state) => {
        return { components };
    },
    setPreview: (preview, _state) => {
        if (preview)
            return { preview: true, isDraggable: false, isResizable: false }
        else {
            return { preview: false, isDraggable: true, isResizable: true }
        }
    },
    setEditComponent: (editComponent, _state) => {
        return { editComponent }
    },
    setEditRemarkComponent: (editRemarkComponent, _state) => {
        return { editRemarkComponent }
    },
    setMemberList: (memberList, _state) => {
        return { memberList };
    },
    /**
     * 控制重新加载组件
     */
    setReLoading: (reLoading, _state) => {
        return { reLoading };
    },
    setReLoad: (reload, _) => {
        return { reload }
    },
    getDashInfo: {
        payload: (params) => {
            return sxAjax.get('/api/services/app/AppObjectDashReport/GetDashInfo?entryId=' + params.entryId + "&appId=" + params.appId);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                const { appId, appName, entryId, name, components, properties } = payload.result;
                let result = { appId, appName, entryId, name };

                if (components && components.length > 0) {
                    const getComponents = [];
                    for (let index = 0; index < components.length; index++) {
                        const component = components[index];
                        getComponents.push(component.attrs)
                    }
                    result.components = getComponents;
                }

                if (properties) {
                    result.properties = properties;
                }
                return (result);
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    saveDashInfo: {
        payload: (params, tip) => {
            return sxAjax.post('/api/services/app/AppObjectDashReport/SaveDashInfo', params, tip === false ? {} : { successTip: '保存成功', errorTip: '保存失败', noEmpty: true });
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                return ({});
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    getAppForms: {
        payload: (params) => {
            return sxAjax.get('api/services/app/AppObject/GetAppForms?appId=' + params.appId);
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                return ({ sourceForms: payload.result });
            },
            complete: (state, action) => {
                return ({});
            },
        }
    },
    getAppOperationPermission: {
        payload: (params) => {
            return sxAjax.get('api/services/app/AppObjectDashReport/GetAppOperationPermission?appId=' + params.appId);
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                return ({ Opermissions: payload.result });
            },
            complete: (state, action) => {
                return ({});
            },
        }
    },
    publishDash: {
        payload: (params) => {
            return sxAjax.post('api/services/app/AppObject/AddAppFormPermissions', params);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                const { data } = payload.result;
                return ({});
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    getDashPublishPermission: {
        payload: (params) => {
            return sxAjax.get('api/services/app/AppObject/GetAppPermissionRoles', params);
        },
        reducer: {
            pending: (state, action) => ({}),
            resolve(state, { payload = {} }) {
                const data = payload.result;
                return ({ memberList: data });
            },
            complete: (state, action) => {
                return ({});
            },
        }
    },
    ExportReportData: {//导出方法
        payload: (params) => {
            return sxAjax.post('/api/services/app/AppObjectDashReport/ReportDataExport', params);
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                if (payload.result !== true) {
                    let url = abp.setting.get("App.Api.Default.Host") + "File/downloadTempFile?" + stringify(payload.result);
                    window.open(url);
                }
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },
    ExportETLReportData: {//导出方法
        payload: (params) => {
            return sxAjax.post(abp.setting.get("App.Api.ETL.Host") + 'api/ETLFactory/ReportDataExport', params, { responseType: 'blob' });
        },
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = {} }) {
                var blob = new Blob([payload], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                var downloadElement = document.createElement('a');
                var href = window.URL.createObjectURL(blob); //创建下载的链接
                downloadElement.href = href;
                downloadElement.download = 'downloads.xlsx'; //下载后文件名
                document.body.appendChild(downloadElement);
                downloadElement.click(); //点击下载
                document.body.removeChild(downloadElement); //下载完成移除元素
                window.URL.revokeObjectURL(href); //释放掉blob对象 
            },
            complete: (state, action) => {
                return ({ loading: false });
            },
        }
    },

}
